/* eslint-disable react/jsx-key */
import React from "react"
import { Link } from "gatsby"

//logo de empresas clientes para mobile
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Cepeban from "@components/sharedComponents/customersLogos/logos/Cepeban.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"

//iconos de precio
import advanced from "@components/pageTicker/pagePrice/image/advanced.svg"
import business from "@components/pageTicker/pagePrice/image/business.svg"

//logos channels
import whatsApp from "@components/pageTicker/pagePrice/image/whatsApp.svg"
import messenger from "@components/pageTicker/pagePrice/image/messenger.svg"
import instagram from "@components/pageTicker/pagePrice/image/instagram.svg"
import chat from "@components/pageTicker/pagePrice/image/chat.svg"
import sms from "@components/pageTicker/pagePrice/image/sms.svg"
import email from "@components/pageTicker/pagePrice/image/email.svg"
import call from "@components/pageScore/pagePrice/images/call.svg"

const checked = (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5068 0.67906L9.91704 15.0584L3.4229 8.59475C2.64923 7.82192 1.38323 7.82192 0.586112 8.59475C-0.187558 9.36758 -0.187558 10.6322 0.586112 11.4285L8.5807 19.4144C8.95581 19.7891 9.47159 19.9999 9.98737 19.9999C10.0108 19.9999 10.0343 19.9999 10.0577 19.9999C10.6204 19.9765 11.1362 19.7423 11.4878 19.3207L25.4842 3.32543C26.211 2.48234 26.1172 1.24112 25.2967 0.491706C24.4995 -0.234289 23.2335 -0.140612 22.5068 0.67906Z"
      fill="#F7D731"
    />
  </svg>
)

const error = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11620_7609)">
      <path
        d="M16.2269 19.3527L10 13.1257L3.7731 19.3527C2.9099 20.2158 1.51049 20.2158 0.647288 19.3527C-0.215763 18.4895 -0.215763 17.0901 0.647288 16.2269L6.87434 10L0.647288 3.7731C-0.215763 2.9099 -0.215763 1.51049 0.647288 0.647288C1.51049 -0.215763 2.9099 -0.215763 3.7731 0.647288L10 6.87434L16.2269 0.647288C17.0901 -0.215763 18.4895 -0.215763 19.3527 0.647288C20.2158 1.51049 20.2158 2.9099 19.3527 3.7731L13.1257 10L19.3527 16.2269C20.2158 17.0901 20.2158 18.4895 19.3527 19.3527C18.4895 20.2158 17.0901 20.2158 16.2269 19.3527Z"
        fill="#E76E54"
      />
    </g>
    <defs>
      <clipPath id="clip0_11620_7609">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const channels = [whatsApp, messenger, instagram, chat, sms, email]
const channelsTwo = [call, whatsApp, messenger, instagram, chat, sms, email]


const channel = (data) => {
  return data.map(item => {
    return <img src={item} alt="canal" key={item} />
  })
}

const infoTwo = [
  <p></p>,
  <p>5</p>,
  <p>{channel(channels)}</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>5</p>,
  <p>Ilimitadas</p>,
  <p>{checked}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{checked}</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>6 meses</p>,
  <p>6 meses</p>,
  <p></p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p></p>,
  <p>Ilimitadas</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p></p>,
  <p>5</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p></p>,
  <p>Beex Docs, Correo, WhatsApp</p>,
]

const infoThree = [
  <p></p>,
  <p>10</p>,
  <p>{channel(channelsTwo)}</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>Ilimitadas</p>,
  <p>6 meses</p>,
  <p>6 meses</p>,
  <p></p>,
  <p>Ilimitadas</p>,
  <p>{checked}</p>,
  <p>2</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p></p>,
  <p>Ilimitadas</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p></p>,
  <p>5</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p></p>,
  <p>Beex Docs, Correo, WhatsApp</p>,
]



const priceTwo = (
  <p className="text-pricing-ticker-advanced">
    $40.00 <span>POR USUARIO</span>
  </p>
)
const priceThree = (
  <p className="text-pricing-ticker-busines">
    $60.00 <span>POR USUARIO</span>
  </p>
)

const data = {
  pricing: [
    {
      title: "DIGITAL",
      price: priceTwo,
      typePrice: "yes",
      classtitle: "card-title-inbox",
      img: advanced,
      info: infoTwo,
      link:
        "/hablemos/",
      boton: "Empezar",
      classlogo: "",
      description:"La licencia Digital está diseñada para empresas que necesitan gestionar su comunicación digital a través de múltiples canales sin requerir servicios de telefonía. Ideal para negocios con un enfoque fuerte en la atención al cliente y marketing digital."
    },
    {
      title: "DIGITAL + VOICE",
      price: priceThree,
      typePrice: "yes",
      classtitle: "card-title-inbox",
      img: business,
      info: infoThree,
      link:
        "/hablemos/",
      boton: "Empezar",
      classlogo: "logo-busines",
      description:"La licencia Digital + Voice combina la gestión de comunicación digital y servicios de telefonía. Es ideal para empresas que necesitan una solución omnicanal para interactuar con clientes tanto a través de canales digitales como telefónicos."
    },
  ],

  question: [
    {
      title: "¿Puedo tener una prueba gratuita?",
      textbody:
        "¡Sí! Dependiendo del número de usuarios que quieras licenciar, puedes acceder a una Demo sin costo por 15 o 30 días. El único costo que asumirás es el de llamadas telefónicas o consumo de WhatsApp. Luego de este tiempo, decides si te quedas con nosotros o no, pero te aseguramos que así será. 😊",
    },
    {
      title: "¿Hay un precio de implementación inicial?",
      textbody:
        "Sí. Dependiendo de la necesidad que tengas como proyecto, el costo de implementación varía. Por lo general va entre $150 a $1,000. Es un único pago previa salida a producción.",
    },
    {
      title: "¿Cómo funciona mi ciclo de facturación?",
      textbody:
        "Siempre facturamos los primeros días de cada mes por el consumo de ese mes, esperando el pago (salvo el acuerdo sea post-pago, según condiciones). Confirmas la cantidad de usuarios a usar y pagas únicamente por ellos.",
    },
    {
      title: "¿Qué pasa cuando agrego nuevos usuarios?",
      textbody:
        "Puedes agregar los usuarios que desees cuando quieras en cualquier licencia. Los usuarios no son prorrateables bajo ningún término, salvo excepciones por cantidad de usuarios contratados, o en el mes que se salió a producción, donde solo se factura el tiempo por lo que los usuarios fueron usados.",
    },
    {
      title:
        "¿Todos los tipos de usuarios tienen el mismo precio de la licencia?",
      textbody:
        "Sí, para los usuarios teleoperadores, supervisores, administradores, calidad, backoffice o nuevos perfiles, son los mismos.",
    },
    {
      title: "¿Cómo funcionan los consumos de telefonía y WhatsApp?",
      textbody:
        <>Los minutos de telefonía y conversaciones/mensajes de WhatsApp se cobran por consumo. Para la telefonía, únicamente se facturan los segundos realmente hablados, sin tomar en cuenta timbrados o parecidos. Para WhatsApp, se facturan las conversaciones y mensajes de sesión consumidos. En ninguno de los casos hay un mínimo mensual que cumplir. Si quieres saber los costos de telefonía por destino, puedes contactarte con nosotros. Para los precios de WhatsApp, <Link href="/whatsapp-business-api/precios/" className="link">ingresa aquí</Link> .</>,
    },
    {
      title: "¿Cuáles métodos de pago aceptan?",
      textbody:
        "Aceptamos las principales tarjetas de crédito, como Visa o Mastercard. También aceptamos transferencias bancarias.",
    },
    {
      title: "¿Puedo hacer downgrade a una licencia menor?",
      textbody:
        "Sí, siempre y cuando haya vencido tu tiempo mínimo de contrato según tu licencia tomada inicialmente.",
    },
    {
      title: "¿Debo firmar un contrato por la solución?",
      textbody:
        "Sí, es necesario firmar un contrato de acuerdo por la cantidad mínima de licencias mensuales y tiempo de compromiso.",
    },
    {
      title: "¿Hay descuentos para organizaciones sin fines de lucro?",
      textbody:
        "Lo hacemos ❤️. Para organizaciones que ayuden a los más necesitados, estamos para darles una mano. Pueden escribirnos para conversar.",
      hr: true,
    },
  ],

  customersDesktop: [
    Rimac,
    Auna,
    Unifef,
    Astara,
    Cepeban,
    CasaRossello,
    Proempresa,
    Savar,
    Cafae,
    Escampa,
    Royal,
    G4S
  ],
  customersTablet: {
    arrayOne: [Rimac, Auna, Unifef, Astara, Cepeban, CasaRossello],
    arrayTwo: [Proempresa, Savar, Cafae, Escampa, Royal, G4S],
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
}

export default data
