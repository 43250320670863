import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"


const ComparePrice = ({ data }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    dots: true,
    speed: 500,
    arrows: true,
  }

  const features = [
    "Características generales",
    "Mínimo de usuarios mensuales",
    "Canales disponibles",
    "Creación de empresas, campañas y bases",
    "Creación de tipificaciones",
    "Creación de formularios",
    "Creación de colas y skills",
    "Supervisión en tiempo real de agentes",
    "Supervisión en tiempo real de llamadas",
    "Monitoreo real online via remote desktop",
    "Dashboards y reportes",
    "Etiquetas y campos customizados",
    "Tickets al mes",
    "Contactos al mes",
    "Tiempo de almacenamiento en data",
    "Tiempo mínimo a contratar",
    "Telefonía",
    "Llamadas al mes",
    "Llamadas entrantes",
    "Números entrantes (DIDs)",
    "Llamadas salientes",
    "IVR entrante",
    "Encuestas de satisfacción via IVR",
    "Marcación telefónica Preview",
    "Marcación telefónica Progresiva",
    "Máscara de números salientes personalizables",
    "Mensajería",
    "Conversaciones al mes",
    "Chatbots",
    "Widget webchat",
    "Campañas masivas de WhatsApp",
    "Campañas masivas de SMS",
    "Plantillas de WhatsApp y SMS",
    "Correo electrónico",
    "Cuentas de correo (buzones)",
    "Campañas masivas de correo",
    "Plantillas de correo electrónico",
    "Soporte",
    "Soporte por",
  ]

  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 1000) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  const [fixedCabecera, setFixedCabecera] = useState(false)

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY >= 3200) {
        setFixedCabecera(false)
      } else if (window.scrollY >= 200) {
        setFixedCabecera(true)
      } else {
        setFixedCabecera(false)
      }
    }
  }, [setFixedCabecera])

  return (
    <section className="container-price-ticker">

      <p className="container-price-ticker-title">
        <TitleUnderline underline="Precios" />   de Beex Conversations
      </p>


      {windowsWidth ? (
        <div className="container-boxprice-ticker">
          <div
            className={`${fixedCabecera ? "container-boxprice-ticker-true" : " "
              }`}
          >
            <div className="cabecera-price-ticker">
              <h2></h2>
              <div className="cabecera-price-ticker-planes">
                {data.map((elemento, i) => {
                  return (
                    <div
                      key={i}
                      className="container-price-ticker__containerfeatures__featureslogo"
                    >
                      <img
                        loading="lazy"
                        src={elemento.img}
                        alt="..."
                        className={elemento.classlogo}
                      />
                      <p className="container-price-ticker__containerfeatures__featurestitle">
                        {elemento.title}
                        <span class="tooltip">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 2.875C5.3934 2.875 2.875 5.3934 2.875 8.5C2.875 11.6066 5.3934 14.125 8.5 14.125C11.6066 14.125 14.125 11.6066 14.125 8.5C14.125 5.3934 11.6066 2.875 8.5 2.875ZM1.375 8.5C1.375 4.56497 4.56497 1.375 8.5 1.375C12.435 1.375 15.625 4.56497 15.625 8.5C15.625 12.435 12.435 15.625 8.5 15.625C4.56497 15.625 1.375 12.435 1.375 8.5ZM8.5 7.04167C8.91421 7.04167 9.25 7.37745 9.25 7.79167V11.3333C9.25 11.7475 8.91421 12.0833 8.5 12.0833C8.08579 12.0833 7.75 11.7475 7.75 11.3333V7.79167C7.75 7.37745 8.08579 7.04167 8.5 7.04167ZM8.5 6.375C8.8912 6.375 9.20833 6.05787 9.20833 5.66667C9.20833 5.27547 8.8912 4.95833 8.5 4.95833C8.1088 4.95833 7.79167 5.27547 7.79167 5.66667C7.79167 6.05787 8.1088 6.375 8.5 6.375Z" fill="#262A31" />
                          </svg>
                          <span class="tooltiptext">{elemento.description}</span>
                        </span>
                      </p>
                      {elemento.price}
                      <Link
                        href={elemento.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-slider-price-white"
                      >
                        {elemento.boton}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={`${fixedCabecera ? "boxprice-ticker-true" : " "}`} />
          <section className="container-price-ticker__containerfeatures">
            {caracteristica(features, windowsWidth)}
            <div className="container-price-ticker__containerfeatures__features">
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-ticker__containerfeatures__features-detail ${Border(
                      elemento
                    )} `}
                    key={elemento}
                  >
                    {Detail(elemento, windowsWidth)}
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      ) : (
        <Slider {...settings}>
          {data.map(elemento => {
            return (
              <>
                <section
                  className={`container-price-ticker__containerfeatures  ${Border(
                    elemento
                  )} `}
                  key={elemento}
                >
                  {caracteristica(features, windowsWidth)}
                  <div className="container-price-ticker__containerfeatures__features">
                    <div
                      className={`container-price-ticker__containerfeatures__features-detail`}
                    >
                      {Detail(elemento, windowsWidth)}
                    </div>
                  </div>
                </section>
              </>
            )
          })}
        </Slider>
      )}
    </section>
  )
}
export default ComparePrice

const Border = elemento => {
  if (elemento.title === "FREE") {
    return "border-free"
  }
  if (elemento.title === "ADVANCED") {
    return "price-border-avanced"
  }
  if (elemento.title === "BUSINESS") {
    return "border-busines"
  }
  if (elemento.title === "ENTERPRISE") {
    return "price-border-enterprise"
  }
}

const Detail = (elemento) => {
  const isHighlightedIndex = index =>
    [0, 16, 26, 33, 37].includes(index);

  return (
    <div>
      {elemento.info.map((item, index) => {
        const itemClass = isHighlightedIndex(index)
          ? "boton-open-description-pricing"
          : index % 2 === 0
            ? "container-price-ticker__item-blue"
            : "";

        return (
          <div
            className={`container-price-ticker__containerfeatures__features-detail--info ${itemClass}`}
            key={index}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

const caracteristica = features => {
  const isHighlightedIndex = index =>
    [0, 16, 26, 33, 37].includes(index);

  return (
    <div className="container-title-caracteristic-price-score">
      {features.map((item, index) => {
        const itemClass = isHighlightedIndex(index)
          ? "boton-open-description-pricing"
          : index % 2 === 0
            ? "container-price-ticker__item-blue"
            : "blanco";

        const titleClass = isHighlightedIndex(index)
          ? "title-open-description-pricing"
          : "";

        return (
          <div className={`container-price-ticker__item ${itemClass}`} key={index}>
            <p className={titleClass}>{item}</p>
          </div>
        );
      })}
    </div>
  );
};